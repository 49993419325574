import apiClient from '@/services/axios'

// const raxConfig = {
//   // Retry 3 times on requests that return a response (500, etc) before giving up.  Defaults to 3.
//   retry: 3,
//   // Retry twice on errors that don't return a response (ENOTFOUND, ETIMEDOUT, etc).
//   noResponseRetries: 2,
//   // Milliseconds to delay at first.  Defaults to 100. Only considered when backoffType is 'static'
//   retryDelay: 100,
//   // HTTP methods to automatically retry.  Defaults to:
//   // ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT']
//   httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT'],
//   // The response status codes to retry.  Supports a double
//   // array with a list of ranges.  Defaults to:
//   // [[100, 199], [429, 429], [500, 599]]
//   statusCodesToRetry: [[100, 199], [429, 429], [500, 599]],
//   // If you are using a non static instance of Axios you need
//   // to pass that instance here (const ax = axios.create())
//   instance: ax,
//   // You can set the backoff type.
//   // options are 'exponential' (default), 'static' or 'linear'
//   backoffType: 'exponential',
//   // You can detect when a retry is happening, and figure out how many
//   // retry attempts have been made
//   onRetryAttempt: err => {
//     const cfg = rax.getConfig(err)
//     console.log(`Retry attempt #${cfg.currentRetryAttempt}`)
//   },
// }

const getByCode = async (id) => {
  const res = await apiClient.get(`/patient/code/${id}`)
  if (res) {
    return res.data
  } else {
    return null
  }
}

const getRedemptionByCode = async (id) => {
  const res = await apiClient.get(`/patient/redemption/${id}`)
  if (res) {
    return res.data
  } else {
    return null
  }
}

const bulkGetByCode = async (id) => {
  const res = await apiClient.get(`/patient/bulk/code/${id}`)
  if (res) {
    return res.data
  } else {
    return null
  }
}

// const create = async (data) => {
//   const res = await apiClient.post('/patient', data, {})
//   if (res) {
//     return res
//   } else {
//     return null
//   }
// }

// const create = async (data) => {
//   const res = await apiClient.post('/patient/dashboardCreate', data, {})
//   if (res) {
//     return res
//   } else {
//     return null
//   }
// }

const create = async (data) => {
  const res = await apiClient.post('/api/patients', data, {})
  // console.log("res", res)
  if (res) {
    return res
  } else {
    return null
  }

}

const sendEmail = async (email) => {
  const res = await apiClient.get('/patient/sendEmail/' + email)
  if (res) {
    return res
  } else {
    return null
  }
}

const confirmEmail = async (id) => {
  const res = await apiClient.get('/patient/account/verifymail/' + id)
  if (res) {
    return res
  } else {
    return null
  }
}

const bulkCreate = async (data) => {
  const res = await apiClient.post('/patient/bulkcreate', data, {})
  if (res) {
    return res
  } else {
    return null
  }
}

const editById = async (id, data) => {
  const res = await apiClient.put(`api/patients/${id}`, data, {})
  if (res) {
    return res
  } else {
    return null
  }
}

const getById = async (id) => {
  const res = await apiClient.get(`api/patients/${id}`)
  if (res) {
    return res.data
  } else {
    return null
  }

}

const deleteById = async (id) => {
  const res = await apiClient.delete(`api/patients/${id}`)
  if (res) {
    return res.data
  } else {
    return null
  }
}

const getRedemptionByPatientAndYear = async (id, year) => {
  const res = await apiClient.get(`/patientredemption/${id}/${year}`)
  if (res) {
    return res.data
  } else {
    return null
  }
}

const getAllPatientData = async (role) => {
  const res = await apiClient.get(`api/patients/`)
  // console.log("res pasien", res.data)
  if (res) {
    // console.log("masukkok")
    return res.data
  } else {
    // console.log("gak masuk")
    return null
  }
}
const getLatestPatientData = async () => {
  const res = await apiClient.get(`/patient/latest`)
  if (res) {
    return res.data
  } else {
    return null
  }
}

export {
  getByCode,
  bulkGetByCode,
  create,
  editById,
  bulkCreate,
  getById,
  deleteById,
  getAllPatientData,
  getRedemptionByPatientAndYear,
  sendEmail,
  getRedemptionByCode,
  confirmEmail,
  getLatestPatientData,
}
